@import "style/index.scss";

.calendar {
  background-color: #fff;
}

.calendar-title {
  position: relative;
  width: 100%;
  height: 3.375rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  @include center;
  column-gap: 2.5rem;
  font-size: 1.25rem;
  font-weight: bold;

  .prev {
    &::after {
      transform: rotate(90deg);
      @include pseudo("icon-arrow-down.png", 1.125rem, 1.125rem);
    }
  }

  .next {
    &::after {
      transform: rotate(-90deg);
      @include pseudo("icon-arrow-down.png", 1.125rem, 1.125rem);
    }
  }
  .next + button {
    position: absolute;
    right: 0;
    padding: 0.25rem 0.75rem;
    background-color: $gray-200;
    border-radius: 0.25rem;
    font-size: 0.875rem;
  }
}

.calendar-cont {
  width: 100%;
  border-top: 1px solid $gray-300;

  span {
    min-width: calc(100% / 7);
    text-align: center;
    border-right: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
  }

  .day {
    height: 2.75rem;
    text-align: center;
    font-weight: bold;
    @include center;

    &:first-child {
      border-left: 1px solid $gray-300;
    }
  }

  .date {
    max-width: calc(100% / 7);
    min-height: 7.5rem;
    padding-top: 0.5rem;
    font-weight: bold;
    border-right: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
    background-color: $gray-200;
    cursor: pointer;

    @extend span;

    &:nth-child(7n + 1) {
      border-left: 1px solid $gray-300;
    }

    &[data-islast="true"] {
      pointer-events: none;
      cursor: no-drop;
      & > .card {
        opacity: 0.5;
      }
    }
  }

  .card {
    width: 96%;
    margin: 0 auto;
    padding: 0.5rem 0;
    border-radius: 0.25rem;
    cursor: pointer;
    font-weight: 500;

    & > ul {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 10rem;
      padding: 0.5rem 0.5rem;
      margin-bottom: 0.5rem;
      font-size: 0.75rem;
      background-color: #fff;

      @extend .card;
    }
    span {
      border: none;
    }

    & > li {
      width: 100%;
      padding: 0.125rem;
      border-right: none;
      border-bottom: none;
      text-align: left;
      font-weight: bold;

      &:first-child {
        @include between-center;

        .status {
          padding: 0 0.5rem;
          font-size: 0.875rem;
          font-weight: 900;
        }
      }
      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 5.625rem;
      }
      &:last-child {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        border: 1px solid $gray-400;
      }

      & > div {
        height: 1.875rem;
        &.cnt {
          @include between-center;
          padding: 0.25rem;
          font-size: 0.875rem;
          & > button:first-child {
            border-bottom: 1px solid $black-900;
          }
        }
      }
      & > button {
        text-align: left;
        &.price {
          font-size: 0.875rem;
          color: $orange-400;
          border-bottom: 1px solid $red-400;
          &::before {
            display: inline-flex;
            content: attr(data-name);
            width: 2rem;
            color: $black-900;
            font-size: 0.75rem;
            font-weight: 600;
            text-align: center;
            color: $orange-400;
          }
        }
      }
    }
  }
}
.md-rm-modify {
  & > label {
    width: 100%;
    text-align: left;
    font-size: 0.875rem;
    padding-top: 1.25rem;
    padding-bottom: 0.5rem;
    &:first-child {
      padding-top: 0;
    }
  }

  & > button {
    width: 3rem;
    height: 2.75rem;
    border-radius: 0.25rem;
    border: 1px solid $gray-300;
  }
}

.md-table {
  width: 100%;
  // margin-top: 1.875rem;
  font-size: 0.875rem;
  border-collapse: collapse;

  tr {
    height: 2.5rem;
  }
  th,
  td {
    border-collapse: collapse;
    border: 0.5px solid $gray-400;
    span {
      &[data-sts] {
        font-weight: bold;
      }
      &[data-sts="C"] {
        color: $green-300;
      }
      &[data-sts="D"] {
        color: $red-400;
      }
      &[data-sts="U"] {
        color: $black-900;
      }
    }
  }
}
