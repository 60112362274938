@import "style/index.scss";

.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  font-size: 14px;
}

.ql-container {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.ql-toolbar {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.ql-container .ql-editor {
  min-height: 7.5rem;
  border-color: $gray-300;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow.ql-disabled {
  background-color: $gray-200;
}
