@import "style/index.scss";

.search-form {
  height: calc(100% - 220px);
  background-color: white;

  .ql-container .ql-editor {
    height: 31.25rem;
    overflow-y: scroll;
  }
}

.term-wrap {
  background-color: #fff;
  padding: 1.875rem 1.875rem 0;
  & > div:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    column-gap: 1.25rem;
    height: 5rem;

    button {
      width: 50%;
      padding: 0.5rem 0;
      background-color: $gray-200;
      text-align: center;
      font-size: 1.125rem;
      font-weight: bold;

      &:hover,
      &.on {
        background-color: $indigo-400;
        color: #fff;
      }
    }
  }
}

.term-list {
  min-height: calc(100vh - 4.0625rem);
  height: 100%;
  padding: 1.875rem 1.25rem 0;
  background-color: #fff;
  ul {
    @include between-center;
    border-bottom: 1px solid $gray-300;

    & > li {
      padding: 0.875rem;
      text-align: left;
      font-size: 1rem;
      cursor: pointer;
      &.on {
        margin-bottom: -1px;
        font-weight: bold;
        border-bottom: 0.125rem solid $indigo-600;
      }
    }
  }
}

.term-detail {
  margin-top: 1.25rem;
  padding: 0.875rem;
  min-height: calc(100vh - 280px);
  max-height: 56.25rem;
  overflow-y: auto;
  border-radius: 0.25rem;
  border-bottom: 1px solid $gray-500;
  font-size: 0.75rem;

  .term-cont {
    max-height: calc(95vh - 6.25rem);
    padding-top: 0.75rem;
    padding-right: 1.25rem;
    padding-bottom: 1.25rem;
    text-align: left;
    overflow-y: auto;

    & > section {
      margin-bottom: 1rem;

      h3 {
        margin: 1rem 0;
      }
      .inner {
        padding-left: 1rem;

        table {
          margin: 1rem 0;
          border-top: 0.1rem solid #ddd;
          border-left: 0.1rem solid #ddd;

          th {
            min-width: 2.5rem;
            padding: 0.5rem;
            font-weight: normal;
            color: #000;
            background: #e8e8e8;
            border-right: 0.1rem solid #ddd;
            border-bottom: 0.1rem solid #ddd;
          }

          td {
            max-width: calc(100% / 5);
            min-width: 2.75rem;
            padding: 0.5rem;
            border-right: 0.1rem solid #ddd;
            border-bottom: 0.1rem solid #ddd;
          }
        }
      }
    }

    strong {
      display: block;
    }

    .font-red {
      color: $red-300;
    }
  }
}
