@import "style/index.scss";

.gbn-wrap {
  position: fixed;
  top: 4rem;
  z-index: 99;
  max-width: $gbn-w;
  width: 40%;
  flex-shrink: 2;
  height: calc(100vh - 4rem);
  background: $indigo-400;
  color: #fff;
  font-size: 1rem;

  button {
    position: relative;
    column-gap: 1.25rem;
    width: 100%;
    height: 3.625rem;
    padding: 0 1rem 0 1.875rem;
    text-align: left;
    font-weight: 600;
    word-wrap: break-word;
    word-break: keep-all;
    transition: all 0.25s ease-in-out;
    @include between-center;

    &::after {
      transform: rotate(180deg);
      @include pseudo("icon_gbn_arrow.svg", 0.625rem, 0.625rem);
      transition: all 0.25s ease-in-out;
    }

    &:hover {
      background-color: $indigo-600;
    }

    span {
      &::before {
        background-color: #fff;
        // @include pseudo("icon_home.svg", 0.875rem, 0.875rem);
      }
    }
  }
  .rotate {
    background-color: $indigo-600;
    transition: all 0.25s ease-in-out;

    &::after {
      transform: rotate(0deg);
    }
    &::before {
      display: inline-block;
      content: "";
      width: 4px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $red-500;
      transition: all 0.25s ease-in-out;
    }
  }

  .no-arrow {
    &::after {
      display: none;
    }
  }

  @media (max-width: $md) {
    font-size: 0.875rem;
    .sub-menu {
      font-size: 0.75rem;

      a {
        padding-left: 2.5rem;
      }
    }
  }
}

.menu-wrap {
  .on {
    color: $red-600;
    background-color: $indigo-500;
  }
}

.sub-menu {
  overflow-y: hidden;

  li {
    height: 3rem;
    color: #fff;
    &:hover {
      background-color: $indigo-600;
    }

    & > a {
      width: 100%;
      height: inherit;
      padding: 0.5rem 0 0.5rem 1.875rem;
      @include start-center;
      &.active {
        color: $red-350;
      }
    }
  }
}
