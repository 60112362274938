@import "./mixin.scss";
@import "./color.scss";

$w-30: 1.875rem;
$w-24: 1.5rem;
$w-20: 1.25rem;
$tbl-max-w: 100rem;
$gbn-w: 12.5rem;

h1 {
  font-size: 1.75rem;
  font-weight: bold;
  @media (max-width: $md) {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;

  @media (max-width: $md) {
    font-size: 1.25rem;
    // font-weight: 600;
  }
}

h3 {
  font-size: 1.125rem;
  font-weight: bold;

  @media (max-width: $md) {
    font-size: 0.875rem;
    font-weight: 600;
  }
}

h4 {
  font-size: 0.875rem;
  font-weight: bold;

  @media (max-width: $md) {
    font-size: 0.75rem;
    font-weight: 600;
  }
}

button,
input {
  outline: none;
  border: none;
}

button {
  &:disabled {
    opacity: 0.8;
    cursor: no-drop;
  }

  .fa-plus-square-o {
    font-size: 1.5rem;
  }

  .fa-refresh {
    font-size: 1.5rem;
    // color: $black-900;
    border-style: dotted;
    &:hover {
      color: $black-450;
    }
  }
}

a {
  &[role="plus"] {
    &::after {
      @include pseudo("icon_plus_black.jpg", 2rem, 2rem);
    }
  }
}

.btn {
  min-width: 3.75rem;
  padding: 0.25rem 0.875rem;
  border-radius: 0.25rem;
  font-size: 0.625rem;
  font-weight: 600;
  border: 0.0625rem solid $black-400;

  &[role="b-plus"]::before {
    position: relative;
    left: -1.25rem;
    top: 2px;
    @include pseudo("icon_plus_black.jpg", 0.875rem, 0.875rem);
  }
}

.btn-red {
  width: 15rem;
  height: 3.125rem;
  border-radius: 0.25rem;
  background-color: rgba($red-400, 1);
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  // @include boxShadow(rgba(146, 85, 88, 0.3));

  @media (max-width: $md) {
    width: 5rem;
    height: 2.125rem;
    font-size: 0.875rem;
  }

  &:hover {
    background-color: rgba($red-400, 0.8);
  }

  &:disabled {
    background-color: $gray-300;
    color: $black-900;
    cursor: no-drop;
  }

  &:active {
    // border: 0.0625rem solid $black-900;
    transform: scale(105%);
  }
}

.btn-gray {
  background-color: $gray-300;
  @extend .btn-red;

  &:hover {
    background-color: rgba($gray-300, 0.8);
  }

  &:active {
    border: 0.0625rem solid $black-900;
    transform: scale(101%);
  }
}

.btn-search {
  width: 7.5rem;
  height: 2.5rem;
  box-shadow: none;
  @extend .btn-red;
}

.btn-reset {
  width: 7.5rem;
  height: 2.5rem;
  // margin-top: 0.75rem;
  color: $black-900;
  box-shadow: none;
  @extend .btn-gray;
}

.btn-loading {
  @extend .btn-red;

  &::before {
    color: #ffffff;
    font-size: 45px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    transform: translateZ(0);
    animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
  }
}

.btn-sm {
  min-width: 4rem;
  width: auto;
  height: 1.875rem;
  padding: 0.25rem 0.5rem;
  background-color: $red-400;
  color: #fff;
  font-weight: bold;
  border-radius: 0.25rem;
  &:disabled {
    background-color: $gray-200;
    color: $black-900;
  }
}

a {
  display: inline-flex;
  text-align: center;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
  -webkit-text-fill-color: #000;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input[type="text"],
input[type="password"],
input[type="number"] {
  flex: 1;
  min-width: 12.5rem;
  width: 100%;
  // height: 3.125rem;
  height: 2.75rem;
  padding-left: 1.25rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid $gray-300;

  &:disabled,
  &:read-only {
    cursor: no-drop;
    background-color: $gray-200;
  }

  // @media (max-width: $md) {
  //   height: 2.5rem;
  //   font-size: 0.75rem;
  // }
}

input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}

// input[type="file"] {
//   display: none;

//   & + label {
//     width: 12.5rem;
//     height: 12.5rem;
//     border: 0.0625rem solid rgba($black-400, 0.2);
//     &[for="file"] {
//       border-radius: 0.5rem;
//       border: 0.0625rem solid rgba($black-400, 0.2);
//       @include center;
//       &::after {
//         @include pseudo("icon_plus_black.jpg", $w-30, $w-30);
//       }
//     }
//   }
// }

input[type="date"] {
  position: relative;
  min-width: 12.5rem;
  max-width: 25rem;
  width: 100%;
  height: 2.75rem;
  padding-left: 1.25rem;
  background: url("/assets/icon/calendar.png") no-repeat right 0.625rem center /
    1.875rem auto;
  border-radius: 8px;
  text-align: left;
  font-size: 100%;
  border-radius: 0.25rem;
  border: 0.0625rem solid #dfe3e9;

  @media (max-width: $md) {
    font-size: 0.75rem;
  }

  &::-webkit-inner-spin-button,
  -webkit-calendar-picker-indicator {
    display: none;
    appearance: none;
  }

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    color: transparent;
    cursor: pointer;
  }

  &::before {
    display: block;
    content: attr(placeholder);
    width: 100%;
    height: 100%;
  }

  &:valid::before {
    display: none;
  }
}

label {
  display: inline-block;
  cursor: pointer;
  @media (max-width: $md) {
    font-size: 0.75rem;
  }
  &[data-dot] {
    &::before {
      content: "*";
      color: $red-300;
      padding-right: 0.25rem;
    }
  }
}

span {
  display: inline-block;
}

label {
  display: inline-block;
  &[role="form"] {
    width: 12.5rem;
    font-size: 1.125rem;

    @media (max-width: $md) {
      width: 7.5rem;
      min-width: 7.5rem;
      font-size: 0.875rem;
    }

    &::after {
      content: "*";
      color: $red-650;
    }
  }
}

.card-form {
  max-width: 43.75rem;
  margin: 0 auto;
  padding: 5rem;
  border-radius: 0.5rem;
  background-color: #fff;
  column-gap: 1.25rem;
  @include boxShadow($gray-450);
}

.search-form {
  width: 100%;
  height: auto;
  padding: 0.625rem 2.5rem 0;
  background-color: #fff;

  .fa-plus-square,
  .fa-plus-circle {
    color: rgba($black-900, 1);
    font-size: 1.875rem;
    font-weight: 900;
    &:hover {
      color: rgba($black-900, 0.7);
    }
  }
}

.table-form {
  max-width: $tbl-max-w;
  min-width: 50rem;
  width: 100%;
  padding: 0.875rem 0;
  font-size: 0.75rem;
  column-gap: 1.25rem;
  @include between-center;
  &[data-dir-col] {
    flex-direction: column;
  }

  .sb-title {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 0.875rem;
    border-bottom: 1px solid $gray-300;
    column-gap: 1.25rem;
    @include start-center;
  }

  h4 {
    // width: 100%;
    font-size: 1rem;
    text-align: left;

    &[data-inf] {
      &::after {
        display: inline-flex;
        padding-left: 1.125rem;
        content: attr(data-inf);
        font-size: 0.75rem;
        color: #f61010;
      }
    }
  }

  table {
    width: 100%;
    table-layout: auto;
  }

  tr {
    max-width: 75rem;
    height: 3.5rem;
    // height: 3.375rem;
    border-bottom: 0.0625rem solid $gray-300;
    font-size: 0.875rem;
    &[data-n-brd] {
      border-bottom: none;
    }
    &[data-t-brd] {
      border-top: 0.0625rem solid $gray-300;
    }
    &[data-b-brd] {
      border-bottom: 0.0625rem solid $gray-300;
    }

    th {
      max-width: 12.5rem;
      width: 12.5rem;
      padding-left: 2.5rem;
      text-align: left;
      word-wrap: break-word;
      word-break: keep-all;
      // flex: 1;
      // border: 1px solid red;

      &:nth-child(even) {
        text-align: right;
      }

      &.top {
        padding-top: 0.875rem;
        vertical-align: top;
      }

      &[data-dot] {
        position: relative;
        &::before {
          position: absolute;
          left: 1.25rem;
          content: "*";
          padding-left: 0.25rem;
          color: $red-300;
          @media (max-width: $md) {
            left: 0.5rem;
          }
        }
      }
    }
    td {
      width: 36%;
      &.full {
        // max-width: calc(100% - 12.5rem);
        width: 86%;
      }
      .txt {
        height: 2.75rem;
        margin: 0.25rem 0;
        padding: 0.875rem;
        border-radius: 0.25rem;
        border: 0.0625rem solid $gray-300;
        background-color: rgba($gray-300, 0.6);
        cursor: no-drop;
        h1,
        h2 {
          font-size: 0.875rem;
          font-weight: 500;
        }
      }
      .cnl-inf {
        span {
          &:first-child {
            width: 10rem;
          }
        }
      }
    }

    &.top {
      vertical-align: top;
    }
    div {
      &.radiobtn-wrap {
        width: 70%;
        @include between-center;
      }
    }
  }

  input[type="text"],
  input[type="number"] {
    max-width: 25rem;
    min-width: 5rem;
    height: 2.75rem;
    font-size: 0.875rem;

    @media (max-width: $md) {
      font-size: 0.75rem;
    }

    &.unit {
      max-width: 22rem;
    }
  }
  input[type="file"]:disabled + label {
    background-color: $gray-200;
  }
  textarea {
    max-width: calc(100%);
    width: 100%;
    max-height: 37.5rem;
    min-height: 2.75rem;
    padding: 0.5rem 0.875rem;
    margin: 0.25rem 0;
    font-size: 0.75rem;
    line-height: 1rem;
    word-wrap: break-word;
    word-break: keep-all;
    border-radius: 0.25rem;
    border: 0.0625rem solid $gray-300;
    resize: none;
    outline: none;

    &:disabled,
    &:read-only {
      background-color: $gray-200;
      cursor: no-drop;
    }
  }
  fieldset {
    width: 100%;
    padding: 0.875rem;
    margin: 0.25rem 0;
    border-radius: 0.25rem;
    border: 0.0625rem solid $gray-300;

    &:disabled {
      background-color: $gray-200;
      cursor: no-drop;
    }
  }

  .desc {
    margin: 0.5rem 0;
    padding: 0.75rem 0.75rem 1.25rem;
    border: 0.0625rem solid $gray-300;
    background-color: $gray-200;
    border-radius: 0.25rem;
  }

  &.border {
    border-bottom: 0.0625rem solid $gray-300;
  }

  .table-btn-wrap {
    padding: 1.25rem 0;

    @include center;
  }
  .btn-mdfy {
    position: absolute;
    right: 0;
    top: 0.5rem;
    min-width: 4rem;
    height: 1.875rem;
    padding: 0.25rem 0.5rem;
    background-color: $red-400;
    border-radius: 0.25rem;
    color: #fff;
    font-weight: bold;
    &:disabled {
      background-color: $gray-200;
      color: $black-900;
    }
    &::before {
      position: absolute;
      left: -8.75rem;
      content: attr(data-inf);
      font-size: 0.6875rem;
      color: $red-400;
    }
  }

  .search-btn-wrap {
    width: 7.5rem;
    padding-left: 1.25rem;
    border-left: 0.0625rem solid $gray-300;
    text-align: right;
  }

  .select-wrap {
    flex: 1;
    max-width: 25rem;
    // margin: auto;
  }

  @media (max-width: $md) {
    tr {
      th {
        max-width: 100px;
        font-size: 0.625rem;
        padding-left: 1.25rem;
      }

      td {
        input[type="text"],
        .select-wrap {
          height: 2.5rem;
        }
      }
    }
  }
}

.btn-form-wrap {
  max-width: $tbl-max-w;
  flex: 1;
  padding: 1.875rem 0;
  column-gap: 1.25rem;

  @include center;

  // & > button {
  //   width: 7.5rem;
  //   font-size: 1rem;
  //   &:first-child {
  //     width: 7.5rem;
  //     margin-top: 0.75rem;
  //     font-size: 1rem;
  //     @include center;
  //     @extend .btn-search;
  //   }
  // }
}

.signup-form {
  margin-top: 0.5rem;

  & > div {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;

    & + span {
      height: 1.875rem;
    }

    @media (max-width: $md) {
      & + span {
        height: 1.5rem;
      }
    }
  }

  input {
    font-size: 0.875rem;
  }

  // .email,
  // .email ~ div {
  //   padding-bottom: 0;
  // }

  label {
    @extend label[role="form"];
  }

  input,
  .authority {
    flex: 1;
    padding-left: 1.25rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid $gray-300;
  }

  .err {
    width: 100%;
    padding-top: 0.125rem;
    padding-left: 12.5rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: $red-650;
    @include flex($horizontal: flex-start, $vertical: flex-start);

    &::before {
      margin-right: 0.625rem;
      @include pseudo("icon_err.png", 0.9375rem, 0.875rem);
      background-position: 1px 1px;
    }

    @media (max-width: $md) {
      padding-left: 7.5rem;
      font-size: 0.625rem;
      &::before {
        margin-right: 0.375rem;
        @include pseudo("icon_err.png", 0.875rem, 0.75rem);
        background-position: 1px 1px;
      }
    }
  }

  .code {
    width: 5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid $gray-300;
    font-size: 0.875rem;
  }
}

.no-logged-disc {
  // margin-top: 1.25rem;
  margin-top: 0.75rem;
  margin-bottom: 1.25rem;
  padding: 0.875rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.25rem;
  color: #fff;
  background-color: $gray-600;
}

.no-logged-wrap {
  position: relative;
  max-width: 43.75rem;
  min-width: 18.75rem;
  padding: 4rem 2.5rem 5rem;
  margin: 0 auto;
  border-radius: 0.75rem;
  background-color: #fff;

  h2 {
    padding-bottom: 1.25rem;
    // margin-bottom: 1.875rem;
    border-bottom: 0.0625rem solid $gray-750;
    @include between-center;
    &[role="autho-mail"]::after {
      @include pseudo("icon_mail.svg", 3.75rem, 3.75rem);
    }
    &[role="mail-done"]::after {
      @include pseudo("icon_complete.svg", 3.75rem, 3.75rem);
    }
    &[role="member-bye"]::after {
      @include pseudo("icon_byebye.svg", 3.75rem, 3.75rem);
    }

    @media (max-width: $md) {
      &[role="autho-mail"]::after {
        @include pseudo("icon_mail.svg", 2.5rem, 2.5rem);
      }
      &[role="mail-done"]::after {
        @include pseudo("icon_complete.svg", 2.5rem, 2.5rem);
      }
      &[role="member-bye"]::after {
        @include pseudo("icon_byebye.svg", 2.5rem, 2.5rem);
      }
    }
  }

  h2 + h3 {
    width: 100%;
    text-align: center;
    font-weight: 500;
    line-height: 2.5rem;

    @media (max-width: $md) {
      line-height: 2rem;
    }
  }

  .lod-slt {
    padding-top: 1.25rem;
    @include start-center;
  }
}

.allcheck-wrap {
  padding: 1.25rem 0;
  margin: 1.25rem 0;
  border-top: 0.125rem solid $gray-750;
  border-bottom: 0.125rem solid $gray-750;
}

/**********************   *******************/

.main-cnt {
  min-height: calc(100vh - 4rem);
  height: calc(100% - 4rem);
  padding: 1.25rem 1.25rem 7.5rem 1.25rem;
  // margin: 6.5rem 2.5rem;
  background-color: #fff;

  .sub-title {
    width: fit-content;
    padding: 1.25rem 1.25rem 0;
    border-bottom: 1px solid $gray-300;

    &.dot {
      &::after {
        content: "(필수입력 사항)";
        padding-left: 0.75rem;
        font-size: 0.75rem;
        color: $red-400;
      }
    }
  }
}

.main-title {
  height: 4rem;
  border-bottom: 0.0625rem solid $gray-400;
  @include between-center;

  .main-btn-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;

    a {
      font-weight: bold;
      font-size: 0.625rem;
    }
  }
}

/*****************  home title  ***************************/

.home-wrap {
  flex: 1;
  font-size: 0.75rem;
  h2 {
    padding-bottom: 1.25rem;
    text-align: left;
    border-bottom: 0.0625rem solid $gray-400;

    @media (max-width: $md) {
      padding: 0.75rem;
    }
  }
  .home-title {
    @include between-center;
    border-bottom: 0.0625rem solid $gray-300;
  }
  button {
    &[role="plus"]::after {
      @include pseudo("icon_plus_black.jpg", $w-30, $w-30);

      @media (max-width: $md) {
        @include pseudo("icon_plus_black.jpg", $w-24, $w-24);
      }
    }
    &[role="refresh"]::after {
      @include pseudo("icon_refresh_circle.png", $w-24, $w-24);

      @media (max-width: $md) {
        @include pseudo("icon_refresh_circle.png", $w-20, $w-20);
      }
    }
  }
}

/****************  IMAGE  UPLOAD  *************************/

input[type="file"] {
  display: none;
}

.img-add {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: $red-400;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  @include center;
  &::after {
    content: "이미지 추가";
    // @include pseudo("icon_plus_black.jpg", $w-30, $w-30);
  }
}
.img-wrap {
  width: 100%;
  column-gap: 0.75rem;
  @include start-center;
  & > img {
    height: 12.5rem;
    border: none;
    overflow: hidden;
  }
}

/***************** INFO DETAIL *******************/
.main-layout {
  position: absolute;
  top: 4.375rem;
  bottom: 0;
  left: 12.5rem;
  right: 0;
  width: calc(100% - 12.5rem);
  height: max-content;
  padding: 1.25rem;
  background-color: #fff;
  // @include boxShadow($gray-450);
  z-index: 9;

  .title {
    border-bottom: 0.0625rem solid $gray-600;

    a {
      padding: 0.25rem 0.75rem;
      font-size: 0.75rem;
      border-radius: 0.25rem;
      border: 0.0625rem solid $black-900;
      text-align: center;

      &:hover {
        font-weight: bold;
      }
    }
  }
}

/***************** REDY PAGE *******************/
.rd-page {
  width: 100%;
  height: 100vh;
  margin-top: 30%;
  font-size: 1.25rem;
  text-align: center;
}
